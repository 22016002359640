import { render, staticRenderFns } from "./Bet365HorseRace4.vue?vue&type=template&id=ab4ddb70&scoped=true&"
import script from "./Bet365HorseRace4.vue?vue&type=script&lang=js&"
export * from "./Bet365HorseRace4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab4ddb70",
  null
  
)

export default component.exports